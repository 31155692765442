<template>
  <el-container>
    <el-main>
      <el-form ref="form" :model="form" label-width="160px" :rules="rules">
        <el-form-item label="资讯主图：" prop="picture">
          <ReadyUploadSource
            @getSource="getImg"
            @removeThis="removeImg"
            :path="form.picture"
            :showStyle="{
              width: '280px',
              height: '120px',
            }"
          ></ReadyUploadSource>
          <div class="tips">建议图片尺寸360*240px,大小不超过1M</div>
        </el-form-item>
        <el-form-item label="资讯标题：" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="资讯副标题：">
          <el-input v-model="form.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="新闻时间：">
          <el-date-picker v-model="form.release_time" type="date" placeholder="默认为当前时间，可以选择时间"></el-date-picker>
          <span style="color: #999; margin-left: 10px">不填则默认为当前时间</span>
        </el-form-item>
        <el-form-item label="是否置顶：" prop="is_top">
          <el-switch v-model="form.is_top" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="是否推荐：" prop="is_recommend">
          <el-switch v-model="form.is_recommend" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="资讯详情：" prop="details">
          <div>
            <RichText :height="300" :width="700" :richTxt="form.details" @soninfo="getRichTxt"></RichText>
          </div>
        </el-form-item>
        <Preservation @preservation="submit('form')">
          <router-link to="/article/articleList" slot="return" v-if="form.id" style="margin-right: 10px">
            <el-button>返回</el-button>
          </router-link>
        </Preservation>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import RichText from '@/components/richText';
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';

export default {
  components: {
    RichText,
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      art_id: null,
      time: '',
      rules: {
        picture: [{ required: true, message: '请添加资讯主图', trigger: 'blur' }],
        title: [{ required: true, message: '请填写资讯标题', trigger: 'blur' }],
        details: [{ required: true, message: '请填写资讯详情', trigger: 'blur' }],
        is_top: [{ required: true }],
        is_recommend: [{ required: true }],
      },
      form: {
        picture: '',
        subtitle: '',
        title: '',
        release_time: '',
        details: '',
        is_top: 1,
        is_recommend: 1,
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.art_id = this.$route.query.id;
      this.getArtInfo();
    }
  },
  methods: {
    submit(val) {
      this.$refs[val].validate(valid => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          if (this.art_id) form.id = this.art_id;
          form.release_time = Math.floor(new Date(form.release_time).getTime() / 1000);
          let url = this.art_id ? this.$api.PCOfficialWebsite.editArt : this.$api.PCOfficialWebsite.addArt;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              this.$message({
                message: form.id ? '编辑成功' : '添加成功',
                type: 'success',
              });
              this.$router.push('/extension/PCOfficialWebsite/NewsInformation');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getImg(val) {
      this.form.picture = val.path;
    },
    removeImg() {
      this.form.picture = '';
    },
    getRichTxt(val) {
      this.form.details = val;
    },
    getArtInfo() {
      this.$axios.post(this.$api.PCOfficialWebsite.artInfo, { id: this.art_id }).then(res => {
        if (res.code === 0) {
          for (const key in this.form) {
            this.form[key] = res.result[key];
            if (key == 'release_time') this.form[key] = res.result[key] * 1000;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;

  .el-main {
    .el-form {
      padding: 0px 0 60px 0;
      .el-form-item {
        width: 800px;
        /deep/ .el-date-editor.el-input {
          width: 300px;
        }
      }
    }
  }
  .tips {
    color: #999999;
    font-size: 12px;
  }
}
</style>
